<template>
  <!-- 購入要求ステート管理Card -->
  <b-card no-body class="mb-5">
    <b-card-body>
      <b-card-title title-tag="h3">購入要求一覧・ステート管理</b-card-title>
      <div class="text-csub">{{ message }}</div>
      <b-form-row>
        <b-col sm="6" md="4" lg="3" class="mb-3">
          <b-form-select v-model="filterState" v-bind:options="filterOptions">
            <template #first>
              <option value="">-- すべてのステート --</option>
            </template>
          </b-form-select>
        </b-col>
        <b-col offset-sm="2" sm="4" offset-md="5" md="3" offset-lg="7" lg="2">
          <b-button
            variant="csub"
            class="w-100 mb-3"
            v-on:click="updateState()"
          >
            <b-icon icon="check2-square"></b-icon>
            ステート更新
          </b-button>
        </b-col>
      </b-form-row>
      <b-table
        v-bind:items="localItems"
        v-bind:fields="fields"
        v-bind:filter="filterState"
        v-bind:filter-included-fields="['state']"
        v-bind:sort-by.sync="sortBy"
        v-bind:sort-desc.sync="sortDesc"
        responsive
        bordered
        sticky-header="500px"
        head-variant="dark"
      >
        <template v-slot:cell(updateStateId)="row">
          <b-select v-model="row.item.updateStateId">
            <option
              v-for="state in updateStateOptions"
              v-bind:value="state.id"
              v-bind:key="state.id"
            >
              {{ state.state }}
            </option>
          </b-select>
        </template>
        <template v-slot:cell(providers)="row">
          <b-button
            size="sm"
            v-on:click="row.toggleDetails"
            variant="csub"
            class="mr-2"
          >
            提供元 {{ row.detailsShowing ? '隠す' : '表示' }}
          </b-button>
        </template>
        <template v-slot:row-details="row">
          <b-card bg-variant="light">
            提供元ワークフロープロバイダー一覧
            <div v-if="row.item.providerList.length == 0">
              データがありません。
            </div>
            <div v-else>
              <b-table
                v-bind:items="row.item.providerList"
                v-bind:fields="providerFields"
                bordered
                class="table-sub"
              >
              </b-table>
            </div>
          </b-card>
        </template>
      </b-table>
      <p v-if="items.length == 0" class="text-center text-caccent">
        購入要求がありません。
      </p>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  props: [
    'items',
    'fields',
    'states',
    'customerId',
    'providerFields',
    'defaultSort',
  ],
  data() {
    return {
      message: '',
      filterState: '',
      localItems: [],
      isUpdate: false,
      sortBy: this.defaultSort.by,
      sortDesc: this.defaultSort.desc,
    }
  },
  created() {
    this.setLocalItems(this.items)
  },
  watch: {
    items(val) {
      console.log('** watch items ***')
      if (this.isUpdate) {
        this.isUpdate = false
      } else {
        // 会員一覧から別の会員を選択し直したときはフィルター解除
        this.filterState = ''
        this.message = ''
      }
      // localItemsを更新
      this.setLocalItems(val)
    },
  },
  computed: {
    filterOptions() {
      // filter用リスト
      return this.states.map((state) => state.state)
    },
    updateStateOptions() {
      // state変更用リスト
      let copystates = JSON.parse(JSON.stringify(this.states))
      copystates.unshift({ id: null, state: '' })
      return copystates
    },
  },
  methods: {
    setLocalItems(orgItems) {
      // 元のリストから更新用ステートの項目追加 これを変更
      let copyItems = JSON.parse(JSON.stringify(orgItems))
      for (let i = 0; i < copyItems.length; i++) {
        copyItems[i].updateStateId = null
      }
      this.localItems = copyItems
    },
    updateState() {
      // 変更したステートに更新
      // 変更ステートがセットされていて、元と同じでないものが対象
      const targetItems = this.localItems.filter(
        (item) =>
          item.updateStateId !== null && item.updateStateId !== item.stateId
      )
      if (targetItems.length == 0) {
        alert('変更するステートがありません。')
        return
      }
      let updateList = []
      let messageList = []
      for (let i = 0; i < targetItems.length; i++) {
        // request用
        updateList.push({
          id: targetItems[i].id,
          stateId: targetItems[i].updateStateId,
        })
        // 更新後のメッセージ用
        messageList.push(targetItems[i].name)
      }
      this.$axiosToken
        .put('/api/customers/' + this.customerId + '/order_states', {
          updateList: updateList,
        })
        .then((response) => {
          console.log('* state update *', response)
          this.message = 'ステートの更新が完了しました。' + messageList
          this.isUpdate = true
          // 一覧更新のため親へ通知
          this.$emit('state-update')
        })
        .catch((error) => {
          console.log(error)
          this.message = ''
          if (error.response) {
            console.log(error.response)
            alert(error.response.data.detail)
          } else {
            alert('ステートの更新に失敗しました。')
          }
        })
    },
  },
}
</script>

<style scoped>
.table-sub {
  width: auto;
  margin-left: 20px;
}
</style>