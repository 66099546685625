<template>
  <!-- データ取引会員管理 -->
  <base-layout v-bind:title="title">
    <!-- PLR基盤との同期 -->
    <template v-slot:pxr>
      <sync-pxr
        v-bind:is-processing="isPxrProcessing"
        v-bind:sync-date="pxrSyncDate"
        v-on:sync-click="syncPxrData()"
      />
    </template>
    <!-- 検索 -->
    <template v-slot:search>
      <search-form
        v-bind:disabled="isPxrProcessing"
        v-on:search-click="getSearchResult"
      />
    </template>
    <!-- 検索結果一覧 -->
    <template v-slot:list>
      <search-result
        v-bind:fields="customerListFields"
        v-bind:items="customerList"
        v-bind:message="listMessage"
        v-bind:target="pageId"
        v-on:row-selected="getDetail($event, (isUpdate = false))"
      />
    </template>
    <!-- 詳細 -->
    <template v-slot:detail v-if="customerSelected">
      <!-- 情報 -->
      <information
        v-bind:customer-info="customerInfo"
        v-on:message-sent="getDetail(customerSelected, (isUpdate = true))"
        v-on:info-updated="
          getDetail(customerSelected, (isUpdate = true), (setListItems = true))
        "
      />
      <!-- 購入要求一覧・ステート管理 -->
      <request-list
        v-bind:fields="requestListFields"
        v-bind:items="requestList"
        v-bind:states="states"
        v-bind:customer-id="customerSelected.id"
        v-bind:provider-fields="providerListFields"
        v-bind:default-sort="defaultSort"
        v-on:state-update="getDetail(customerSelected, (isUpdate = true))"
      />
      <!-- メッセージ履歴 -->
      <message-list v-bind:messages="messages" />
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from '@/components/common/BaseLayout'
import SearchForm from '@/components/customer/SearchForm'
import SearchResult from '@/components/common/SearchResult'
import Information from '@/components/customer/Information'
import RequestList from '@/components/customer/RequestListCard'
import MessageList from '@/components/common/MessageListCard'
import SyncPxr from '@/components/common/SyncPxrData'
import { convertTimezone, getPxrSyncDate } from '@/mixins/utility'
export default {
  components: {
    BaseLayout,
    SearchForm,
    SearchResult,
    Information,
    RequestList,
    MessageList,
    SyncPxr,
  },
  mixins: [convertTimezone, getPxrSyncDate],
  data() {
    return {
      title: '会員',
      pageId: 2,
      // ***** 検索結果データ取引会員一覧 *****
      customerList: [],
      customerListFields: [
        { key: 'check', label: '', tdClass: 'text-center' },
        { key: 'actorCode', label: 'ActorCode' },
        { key: 'name', label: '名称' },
        { key: 'contact', label: '担当者' },
        { key: 'email', label: '担当者連絡先メールアドレス' },
        { key: 'certifiedAt', label: '登録日', tdClass: 'text-center' },
        { key: 'button', label: '連絡', tdClass: 'text-center' },
      ],
      customerSelected: null,
      listMessage: null,
      // ***** 選択データ取引会員詳細 *****
      // データ取引会員情報
      customerInfo: {
        id: null,
        actorCode: null,
        name: null,
        contact: null,
        email: null,
      },
      // 購入要求ステート一覧 ******
      requestList: [],
      requestListFields: [
        { key: 'id', label: '契約ID', tdClass: 'text-center' },
        { key: 'contractState', label: '契約ステータス' },
        { key: 'name', label: '契約名' },
        { key: 'providers', label: '提供元', tdClass: 'text-center' },
        { key: 'state', label: 'ステート' },
        { key: 'updateStateId', label: 'ステート変更', tdClass: 'text-center' },
        { key: 'dateOfEstimation', label: '見積もり', tdClass: 'text-center' },
        { key: 'dateOfOrder', label: '注文受付', tdClass: 'text-center' },
        { key: 'dateOfBill', label: '請求書', tdClass: 'text-center' },
        { key: 'dateOfPayment', label: '支払い', tdClass: 'text-center' },
        { key: 'dateOfDelivery', label: '納品', tdClass: 'text-center' },
        {
          key: 'minParticipants',
          label: '募集人数（最小）',
          tdClass: 'text-right',
        },
        {
          key: 'maxParticipants',
          label: '募集人数（最大）',
          tdClass: 'text-right',
        },
        {
          key: 'numOfConsents',
          label: '被験者許諾人数',
          tdClass: 'text-right',
        },
      ],
      providerListFields: [
        {
          key: 'actorCode',
          label: 'ActorCode',
          tdClass: 'text-center',
        },
        { key: 'name', label: 'ワークフロープロバイダー' },
        { key: 'state', label: '状況', tdClass: 'text-center' },
      ],
      defaultSort: { by: 'id', desc: true },
      states: [],
      // メッセージ履歴
      messages: [],
      // PLR基盤との同期
      isPxrProcessing: false,
      pxrSyncDate: null,
    }
  },
  created() {
    // 最終同期日時取得
    this.getPxrSyncDate(this.pageId).then((ret) => {
      this.pxrSyncDate = this.convertTimezone(ret, false)
    })
  },
  methods: {
    syncPxrData() {
      // PLR基盤とのデータ同期
      console.log('** pxr data sync **')
      this.isPxrProcessing = true
      // 表示されているものはクリア
      this.customerList = []
      this.customerSelected = null

      this.$axiosToken
        .post('/api/update/all')
        .then((response) => {
          console.log(response)
          // 同期日時取得
          this.getPxrSyncDate(this.pageId).then(
            (ret) => (this.pxrSyncDate = this.convertTimezone(ret, false))
          )
        })
        .catch((error) => {
          console.log(error)
          if (error.response) {
            console.log(error.response)
            alert(error.response.data.detail)
          } else {
            alert('PLR基盤からのデータ取得に失敗しました。')
          }
        })
        .finally(() => {
          this.isPxrProcessing = false
        })
    },
    getSearchResult(searchItems) {
      // データ取引会員一覧取得
      console.log('searchItems', searchItems)
      this.listMessage = null
      this.$axiosToken
        .get('/api/customers', {
          params: {
            actor_code: searchItems.actorCode,
            name: searchItems.name,
            contact: searchItems.contact,
            email: searchItems.email,
          },
        })
        .then((response) => {
          console.log('** customer list **', response)
          this.customerList = response.data.customerList
          // 日付項目をLocalTimeに変換
          for (let i = 0; i < this.customerList.length; i++) {
            this.customerList[i].certifiedAt = this.convertTimezone(
              this.customerList[i].certifiedAt
            )
          }
          if (this.customerList.length == 0) {
            this.listMessage = '該当するデータ取引会員がありません。'
          }
        })
        .catch((error) => {
          console.log(error)
          this.customerList = []
          this.listMessage = 'データ取引会員の取得に失敗しました。'
        })
    },
    getDetail(selectedRow, isUpdate, setListItems = false) {
      // 選択されたデータ取引会員の詳細取得 isUpdate true:更新 false:行選択で新たに取得
      // setListItems=true:一覧を再取得せず表示の値を更新しておく
      console.log('** get detail **', selectedRow, isUpdate)
      if (!isUpdate && !selectedRow) {
        this.customerSelected = null
        console.log('*** 選択解除')
        return
      }
      // -- state一覧get --
      this.$axiosToken
        .get('/api/customers/states')
        .then((response) => {
          console.log('** get state list **', response)
          this.states = response.data.stateList
        })
        .catch((error) => {
          console.log(error)
        })
      // -- データ取引会員詳細get --
      this.$axiosToken
        .get('/api/customers/' + selectedRow['id'])
        .then((response) => {
          console.log('** get customer detail **', response)
          this.customerInfo.id = response.data.id //info
          this.customerInfo.actorCode = response.data.actorCode //info
          this.customerInfo.name = response.data.name //info
          this.customerInfo.contact = response.data.contact //info
          this.customerInfo.email = response.data.email //info
          this.requestList = response.data.orderList //request
          // 日付項目をLocalTimeに変換
          console.log(this.requestList)
          for (let i = 0; i < this.requestList.length; i++) {
            this.requestList[i].dateOfEstimation = this.convertTimezone(
              this.requestList[i].dateOfEstimation
            )
            this.requestList[i].dateOfOrder = this.convertTimezone(
              this.requestList[i].dateOfOrder
            )
            this.requestList[i].dateOfBill = this.convertTimezone(
              this.requestList[i].dateOfBill
            )
            this.requestList[i].dateOfPayment = this.convertTimezone(
              this.requestList[i].dateOfPayment
            )
            this.requestList[i].dateOfDelivery = this.convertTimezone(
              this.requestList[i].dateOfDelivery
            )
          }
          this.messages = response.data.messageLog //message
          // 日付項目をLocalTimeに変換
          for (let i = 0; i < this.messages.length; i++) {
            this.messages[i].date = this.convertTimezone(
              this.messages[i].date,
              false
            )
          }
          if (!isUpdate) {
            this.customerSelected = selectedRow
          }
          if (setListItems) {
            const targetRow = this.customerList.find(
              (r) => r.id === this.customerInfo.id
            )
            targetRow['contact'] = this.customerInfo.contact
            targetRow['email'] = this.customerInfo.email
          }
        })
        .catch((error) => {
          console.log(error)
          // 詳細表示ありで行選択でエラーの場合はクリア 更新の時は表示したままにする
          if (isUpdate) {
            alert('データ取引会員詳細の更新に失敗しました。')
          } else {
            this.customerSelected = null
            alert('データ取引会員詳細の取得に失敗しました。')
          }
        })
    },
  },
}
</script>
