<template>
  <!--  データ取引会員検索フォーム -->
  <b-form v-on:submit.prevent="onSearchClick()">
    <b-form-row>
      <b-col md="4" lg="3">
        <base-input
          v-model="search.actorCode"
          label="ActorCode"
          id="search-actor-code"
          pattern="^[0-9]+$"
          title="数値のみ"
        >
        </base-input>
      </b-col>
      <b-col md="8" lg="9">
        <base-input v-model="search.name" label="名称*" id="search-name">
        </base-input>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col md="6" lg="3">
        <base-input
          v-model="search.contact"
          label="担当者*"
          id="search-contact"
        >
        </base-input>
      </b-col>
      <b-col md="6" lg="3">
        <base-input
          v-model="search.email"
          label="担当者連絡先メールアドレス*"
          id="search-email"
        >
        </base-input>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col offset-sm="4" sm="4" offset-lg="5" lg="2">
        <b-button
          type="submit"
          variant="csub"
          class="w-100"
          v-bind:disabled="disabled"
        >
          <b-icon icon="search"></b-icon>
          検索
        </b-button>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import BaseInput from '@/components/common/BaseInput'
export default {
  components: {
    BaseInput,
  },
  props: ['disabled'],
  data() {
    return {
      search: {
        actorCode: null,
        name: null,
        contact: null,
        email: null,
      },
    }
  },
  methods: {
    onSearchClick() {
      this.$emit('search-click', this.search)
    },
  },
}
</script>
